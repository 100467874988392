.accepted_page {
  padding: 20px;
}

.accepted_page .filter_accepted {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.accepted_page .filter_accepted>div {
  width: 48%;
}

.from_pay .from_input {
  position: relative;
}

.from_pay .from_input input {
  height: 30px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.from_pay .from_input img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.pay_methods {
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
  z-index: 9999;
  border-radius: 10px;
  padding: 10px;
}

.pay_methods .pay_method {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  padding-top: 4px;
}

.pay_methods .pay_method:hover {
  background-color: #ccc;
  border-radius: 4px;
}

.pay_methods .pay_method img {
  position: inherit;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: -4px;
}

.pay_input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.accepted_page .filter_accepted .date_filter input {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.ant-pagination-prev {
  direction: rtl !important;
  transform: rotate;
  rotate: 180deg;
}

@media (max-width: 767px) {
  .accepted_page .filter_accepted>div {
    width: 100%;
  }
}

.ant-pagination-next {
  rotate: 180deg;
}

/* .ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-14wwjjs {
  direction: ltr;
} */

.ac_form {
  width: min(400px, 100%);
  padding: 30px;
  background: white;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: absolute;
  z-index: 3;
}

.ac_form>* {
  display: block;
  width: 90%;
  margin: 7px 0;
  position: relative;
}


.ac_form span,
.ac_form button,
.btn-o {
  padding: 5px 19px;
  border: .1px solid;
  border-radius: 3px;
  width: fit-content !important;
  cursor: pointer;
  /* text-align: center; */
  margin-left: auto;
  transition: .5s ease-in-out;
}

.ac_form button,
.btn-o {
  background-color: #30307a;
  color: white;
  padding: 12px 52px;
  font-size: 17px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  margin-left: 10px;
  font-weight: 700;
  border-radius: 7px;
  margin: 10px 0;
}

.ac_form button:hover,
.btn-o:hover {
  background: transparent;
  color: black;
}

.ac_form>div>* {
  width: 107%;
  height: 40px;
  /* transform: translate(-50%, -50%); */
}

.date_filter .ant-select.css-14wwjjs.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}

.ac_form>div>input {
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
}

.ac_form>div>label {
  text-transform: uppercase;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  transition: 1s;
}

.updateformwallet {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.updateformwallet>div {
  width: 100%;
}

.updateformwallet input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}